<template>
  <el-tabs
    v-model="activeTab"
    class="top-tabs overflow-y-auto overflow-x-hidden carsTabs"
    style="height: 500px"
  >
    <el-tab-pane v-for="(tab, index) in items" :key="index" :name="tab.label">
      <span slot="label">
        <div
          class="w-5 h-5 rounded-full"
          :style="'background-color:' + tab.color"
        ></div>
      </span>
      <div
        v-for="(item, index) in icons"
        class="inline-block"
        :key="index"
        @click="selectIcon(item)"
      >
        <div
          class="border p-1 rounded hover_shadow-md transition duration-300"
          :style="{
            padding: '5px',
            width: 'auto',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }"
          shadow="hover"
        >
          <!-- Имя иконок -->
          <span
            class="rounded-full flex"
            style="
              border: 3px solid #d7e0e8;
              width: 42px;
              height: 42px;
              justify-content: center;
              align-items: center;
            "
          >
            <svg
              width="56"
              height="50"
              viewBox="0 -4 56 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use
                :xlink:href="`/static/car_icon-new/cars.svg#${item}`"
                :fill="tab.color"
              ></use>
            </svg>
          </span>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapGetters } from 'vuex'
// массив иконок с именами
import iconSort from '@/utils/icons.js'

export default {
  props: {
    unit: {
      type: Object
    }
  },

  data() {
    return {
      activeTab: 'red',
      items: [
        {
          color: '#eb5757',
          label: 'red'
        },
        {
          color: '#1bb934',
          label: 'green'
        },
        {
          color: '#1585d8',
          label: 'blue'
        },
        {
          color: '#808080bf',
          label: 'grey'
        },
        {
          color: '#e68a2f',
          label: 'orange'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      unitIcon: 'dictionary/unitIcon'
    }),
    icons() {
      return iconSort
    }
  },

  methods: {
    selectIcon(item) {
      this.$emit('select-icon', item, this.activeTab)
    }
  }
}
</script>
<style lang="css">
.carsTabs .el-tabs__content .el-tab-pane {
  margin-right: 7px;
}
</style>
