var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-tabs',{staticClass:"top-tabs overflow-y-auto overflow-x-hidden carsTabs",staticStyle:{"height":"500px"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.items),function(tab,index){return _c('el-tab-pane',{key:index,attrs:{"name":tab.label}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"w-5 h-5 rounded-full",style:('background-color:' + tab.color)})]),_vm._l((_vm.icons),function(item,index){return _c('div',{key:index,staticClass:"inline-block",on:{"click":function($event){return _vm.selectIcon(item)}}},[_c('div',{staticClass:"border p-1 rounded hover_shadow-md transition duration-300",style:({
          padding: '5px',
          width: 'auto',
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }),attrs:{"shadow":"hover"}},[_c('span',{staticClass:"rounded-full flex",staticStyle:{"border":"3px solid #d7e0e8","width":"42px","height":"42px","justify-content":"center","align-items":"center"}},[_c('svg',{attrs:{"width":"56","height":"50","viewBox":"0 -4 56 60","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('use',{attrs:{"xlink:href":`/static/car_icon-new/cars.svg#${item}`,"fill":tab.color}})])])])])})],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }