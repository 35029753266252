const iconSort = [
  'ambulance',
  'asphalt_tanker',
  'autobus',
  'autocrane',
  'bucket_truck',
  'bulldozer',
  'cabriolet',
  'car2',
  'cargo',
  'car',
  'combined_machine',
  'combine_truck',
  'concrete_mixer',
  'concrete_paver',
  'convertible',
  'drilling_tractor',
  'pickup_without_cargo',
  'dump_truck2',
  'excavator',
  'forklift',
  'fridge',
  'garbage_truck',
  'gaselle',
  'gasoline_tanker',
  'grader',
  'grain_harvester',
  'hatchback',
  'hydraulic_hammer',
  'jeep',
  'manipulator',
  'other',
  'parking',
  'paver',
  'pickup_with_cargo',
  'pipe_machine',
  'pump_truck',
  'racing2',
  'racing',
  'recycler',
  'rig_truck',
  'roll_truck',
  'schoolbus',
  'snowplow',
  'snow_remover',
  'tow_truck',
  'tractor',
  'tractor_unit',
  'trailer_truck',
  'trencher',
  'truck_rally',
  'truck',
  'truck2',
  'truck_with_cargo',
  'universal',
  'vacuum_truck',
  'van_house2',
  'van_house',
  'van_trailer2',
  'van_trailer',
  'van_universal2',
  'van_universal3',
  'van_universal4',
  'van_universal',
  'van_vw',
  'water_truck',
  'train',
  'base',
  'bicycle',
  'block_container',
  'boring_machine',
  'car_lifter',
  'crane',
  'delivery_service',
  'field',
  'gas_station',
  'generator',
  'motorcycle',
  'moto',
  'scooter',
  'trailer',
  'boat',
  'sailboat',
  'yacht',
  'air_balloon',
  'helicopter',
  'plane',
  'quadrocopter',
  'vibrating_plate',
  'delivery_man',
  'disabled_person',
  'driver',
  'engineer',
  'firefighter',
  'mechanic',
  'office_worker',
  'old_man',
  'policeman',
  'porter',
  'repairman',
  'schoolkid',
  'spec_forces',
  'bull',
  'cat',
  'chick',
  'dog',
  'goat',
  'goose',
  'pig'
]

export default iconSort
